import { MemesSection } from "../componentsMuseum/MemesSection"

export const Memes = () => {
    return (
        <div>
       <MemesSection/>
       
        </div>
    )
}
