import React, { useState } from 'react';
import introImage from './img/utility.png';
import heyImage from './img/hey.png';
import bloomersImage from './img/bloomers.png';
import orbImage from './img/orb.png';
import kairaImage from './img/kaira.png';
import butterflyImage from './img/butterfly.png';
import fireflyImage from './img/firefly.png';

export const Socials = () => {
    const [isHeyHovered, setIsHeyHovered] = useState(false);
    const [isKairaHovered, setIsKairaHovered] = useState(false);
    const [isOrbHovered, setIsOrbHovered] = useState(false);
    const [isBloomersHovered, setIsBloomersHovered] = useState(false);
    const [isLensportHovered, setIsLensportHovered] = useState(false);
    const [isFireflyHovered, setIsFireflyHovered] = useState(false);

    const backgroundGradient = 'linear-gradient(to bottom right, #FCE38A, #F38181)';
    const blackTextColor = '#000000'; 

    const articleContainerStyle: React.CSSProperties = {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(40%, 1fr))',
        gap: '20px',
        maxWidth: '1000px',
        margin: '0 auto',
    };

    const articleStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '2px 2px 4px rgba(0,0,0,0.5)',
        transition: 'background 0.5s ease-in-out, color 0.5s ease-in-out',
        background: 'linear-gradient(to bottom right, #2c3930, #C3E5CD)',
        position: 'relative',
        minHeight: '260px',
        color: blackTextColor, 
    };

    const imageStyle: React.CSSProperties = {
        width: '40%',
        marginRight: '20px',
        borderRadius: '10px',
    };

    const paragraphStyle: React.CSSProperties = {
        fontSize: '1em',
        width: '90%',
        textAlign: 'left',
    };

    const buttonnStyle: React.CSSProperties = {
        padding: '10px 20px',
        background: '#2c3930',
        color: '#FFFFFF',
        borderRadius: '5px',
        border: 'none',
        cursor: 'pointer',
        transition: 'background-color 0.3s, transform 0.3s, color 0.3s',
        fontFamily: 'Helvetica, sans-serif',
        fontWeight: 'bold',
        transform: 'translate(-50%, 1px)',
        position: 'absolute',
        bottom: '0',
        left: '50%',
    };

    const buttonBottomStyle: React.CSSProperties = {
        ...buttonnStyle,
        borderBottomLeftRadius: '0',
        borderBottomRightRadius: '0',
        boxShadow: 'none',
    };

    return (
        <div>
            <img
                src={introImage}
                alt="Introduction"
                className="intro-image"
                style={{
                    width: '100%',
                    marginTop: window.innerWidth < 769 ? '17%' : '4%',
                    maxWidth: '100%',
                    display: 'block',
                }}
            />

            <section style={{ textAlign: 'center', color: 'white' }}>
                <div style={window.innerWidth < 769 ? { ...articleContainerStyle, gridTemplateColumns: '1fr' } : articleContainerStyle}>
                    <div
                        style={{
                            ...articleStyle,
                            background: isKairaHovered ? backgroundGradient : articleStyle.background,
                            color: isKairaHovered ? blackTextColor : 'inherit', 
                        }}
                        onMouseEnter={() => setIsKairaHovered(true)}
                        onMouseLeave={() => setIsKairaHovered(false)}
                    >
                        <h1>Kaira.network</h1>
                        <img src={kairaImage} alt="Introduction" className="intro-image" style={{ ...imageStyle }} />
                        <p style={{ ...paragraphStyle }}>What to do with $pointless ? </p>
                        <li style={{ ...paragraphStyle }}>Create / Collect Paid Posts</li>
                        <li style={{ ...paragraphStyle }}>Setup / Subscribe to Super Follows</li>
                        <li style={{ ...paragraphStyle }}>Participate in Pointless memes contests</li>
                        <li style={{ ...paragraphStyle }}>Enable / Send Creator Tips (Coming Soon)</li>
                        <div style={{ height: '40px' }}></div>
                        <a href="https://kaira.network" target="_blank" rel="noopener noreferrer">
                            <button style={buttonBottomStyle}>Visit Kaira</button>
                        </a>
                    </div>

                    <div
                        style={{
                            ...articleStyle,
                            background: isHeyHovered ? backgroundGradient : articleStyle.background,
                            color: isHeyHovered ? blackTextColor : 'inherit', 
                        }}
                        onMouseEnter={() => setIsHeyHovered(true)}
                        onMouseLeave={() => setIsHeyHovered(false)}
                    >
                        <h1>Hey.xyz</h1>
                        <img src={heyImage} alt="Introduction" className="intro-image" style={{ ...imageStyle }} />
                        <p style={{ ...paragraphStyle }}>What to do with $pointless ? </p>
                        <li style={{ ...paragraphStyle }}>Create / Collect Paid Posts</li>
                        <li style={{ ...paragraphStyle }}>Setup / Subscribe to Super Follows</li>
                        <li style={{ ...paragraphStyle }}>Enable / Send Creator Tips</li>
                        <div style={{ height: '40px' }}></div>
                        <a href="https://hey.xyz" target="_blank" rel="noopener noreferrer">
                            <button style={buttonBottomStyle}>Visit Hey</button>
                        </a>
                    </div>

                    <div
                        style={{
                            ...articleStyle,
                            background: isOrbHovered ? backgroundGradient : articleStyle.background,
                            color: isOrbHovered ? blackTextColor : 'inherit', 
                        }}
                        onMouseEnter={() => setIsOrbHovered(true)}
                        onMouseLeave={() => setIsOrbHovered(false)}
                    >
                        <h1>Orb.club</h1>
                        <img src={orbImage} alt="Introduction" className="intro-image" style={{ ...imageStyle }} />
                        <p style={{ ...paragraphStyle }}>What to do with $pointless ? </p>
                        <li style={{ ...paragraphStyle }}>Create / Collect Paid Posts</li>
                        <li style={{ ...paragraphStyle }}>Join the Pointless Club (Hold 5M $pointless) </li>
                        <li style={{ ...paragraphStyle }}>Participate in Pointless memes contests</li>
                        <div style={{ height: '40px' }}></div>
                        <a href="https://orb.club" target="_blank" rel="noopener noreferrer">
                            <button style={buttonBottomStyle}>Visit Orb</button>
                        </a>
                    </div>



                    <div
                        style={{
                            ...articleStyle,
                            background: isLensportHovered ? backgroundGradient : articleStyle.background,
                            color: isLensportHovered ? blackTextColor : 'inherit', 
                        }}
                        onMouseEnter={() => setIsLensportHovered(true)}
                        onMouseLeave={() => setIsLensportHovered(false)}
                    >
                        <h1>Buttrfly.app</h1>
                        <img src={butterflyImage} alt="Introduction" className="intro-image" style={{ ...imageStyle }} />
                        <p style={{ ...paragraphStyle }}>What to do with $pointless ? </p>
                        <li style={{ ...paragraphStyle }}>Create / Collect Paid Posts</li>
                        <li style={{ ...paragraphStyle }}>Enable / Send Creator Tips (Coming Soon)</li>
                        <div style={{ height: '40px' }}></div>
                        <a href="https://buttrfly.app/explore" target="_blank" rel="noopener noreferrer">
                            <button style={buttonBottomStyle}>Visit Buttrfly</button>
                        </a>
                    </div>





                    

                    

                    <div
                        style={{
                            ...articleStyle,
                            background: isFireflyHovered ? backgroundGradient : articleStyle.background,
                            color: isFireflyHovered ? blackTextColor : 'inherit', 
                        }}
                        onMouseEnter={() => setIsFireflyHovered(true)}
                        onMouseLeave={() => setIsFireflyHovered(false)}
                    >
                        <h1>Firefly.mask.social</h1>
                        <img src={fireflyImage} alt="Introduction" className="intro-image" style={{ ...imageStyle }} />
                        <p style={{ ...paragraphStyle }}>What to do with $pointless ? </p>
                        <li style={{ ...paragraphStyle }}>Create / Participate to LuckyDrops </li>
                        <li style={{ ...paragraphStyle }}>Create Paid Posts (Collect coming soon) </li>
                        <div style={{ height: '40px' }}></div>
                        <a href="https://Firefly.mask.social" target="_blank" rel="noopener noreferrer">
                            <button style={buttonBottomStyle}>Visit Firefly</button>
                        </a>
                    </div>



                    <div
                        style={{
                            ...articleStyle,
                            background: isBloomersHovered ? backgroundGradient : articleStyle.background,
                            color: isBloomersHovered ? blackTextColor : 'inherit', 
                        }}
                        onMouseEnter={() => setIsBloomersHovered(true)}
                        onMouseLeave={() => setIsBloomersHovered(false)}
                    >
                        <h1>Bloomers.tv</h1>
                        <img src={bloomersImage} alt="Introduction" className="intro-image" style={{ ...imageStyle }} />
                        <p style={{ ...paragraphStyle }}>What to do with $pointless ? </p>
                        <li style={{ ...paragraphStyle }}>Enable / Send Creator Tips on Streams (Coming Soon) </li>
                        <div style={{ height: '40px' }}></div>
                        <a href="https://Bloomers.tv" target="_blank" rel="noopener noreferrer">
                            <button style={buttonBottomStyle}>Visit Bloomers</button>
                        </a>
                    </div>




                </div>
            </section>
        </div>
    );
};
