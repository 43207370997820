import React, { useState } from 'react';
import { Routes, Route, NavLink } from 'react-router-dom';
import logo from './img/nameDomain.svg';
import Footer from './componentesGeneral/Footer';
import { Home } from './screens/Home';
import { Memes } from './screens/Memes';
import { History } from './screens/History';
import { Tokenomics } from './screens/Tokenomics';
import { Socials } from './screens/Socials';
import { Games } from './screens/Games';
import '@rainbow-me/rainbowkit/styles.css';
import { getDefaultConfig, RainbowKitProvider, ConnectButton } from '@rainbow-me/rainbowkit';
import { WagmiProvider, useAccount, useReadContract } from 'wagmi';
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { polygon } from 'wagmi/chains';
import './App.css';

const config = getDefaultConfig({
  appName: 'Pointless',
  projectId: 'POINTLESS_APP',
  chains: [polygon],
  ssr: true,
});

function MobileNav() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="mobile-navbar">
      <img src={logo} alt="Logo" />
      <button className={`menu-toggle ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="menu-icon" />
        <div className="menu-icon" />
        <div className="menu-icon" />
      </button>
      {isMenuOpen && (
        <div className="mobile-menu">
          <NavLink to='/' className="nav-link" onClick={toggleMenu}>Home</NavLink>
          <NavLink to='/about/history' className="nav-link" onClick={toggleMenu}>History</NavLink>
          <NavLink to='/about/tokenomics' className="nav-link" onClick={toggleMenu}>Tokenomics</NavLink>
          <NavLink to='/memes' className="nav-link" onClick={toggleMenu}>Memes</NavLink>
          <a href="https://app.safe.global/home?safe=matic:0x810B93F0DEc3a84AA3B8a210D033858fbEE41204" className="nav-link" target="_blank" rel="noopener noreferrer" onClick={toggleMenu}>Multisig</a>
          <NavLink to='/about/socials' className="nav-link" onClick={toggleMenu}>Socials</NavLink>
          <NavLink to='/about/games' className="nav-link" onClick={toggleMenu}>Games</NavLink>
        </div>
      )}
    </nav>
  );
}

function Balance() {
  const account = useAccount({ config });
  const { data: userBalance } = useReadContract({
    address: "0x9B8cc6320F22325759B7D2CA5CD27347bB4eCD86",
    abi: [
      {
        "constant": true,
        "inputs": [
          {
            "name": "_owner",
            "type": "address"
          }
        ],
        "name": "balanceOf",
        "outputs": [
          {
            "name": "balance",
            "type": "uint256"
          }
        ],
        "payable": false,
        "type": "function"
      }
    ],
    functionName: "balanceOf",
    args: [account.address],
  });

  const formattedBalance = userBalance ? (parseFloat(userBalance.toString()) / 1e18).toLocaleString('en-US', { maximumFractionDigits: 0 }) : 'Loading...';
  const image = userBalance ? "" : "";

  if (userBalance) {
    return (
      <div className="balance-container">
        <div className="balance">
          <p className="balanceText">Balance: {formattedBalance}</p>
          <img height="20px" src="/favicon.ico" alt="Favicon" />
        </div>
      </div>
    );
  } else {
    return null;
  }
}

function App() {
  const queryClient = new QueryClient();

  return (
    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>
          <React.Fragment>
            <div className="app-container">
              <nav className="navbar">
                <img src={logo} alt="Logo" />
                <NavLink to='/' className="nav-link">Home</NavLink>
                <AboutDropdown />
                <NavLink to='/memes' className="nav-link">Memes</NavLink>
                <UtilitiesDropdown />
                <DaoDropdownMenu />
              </nav>
              <MobileNav />
              <Balance />
              <div className="spacer"></div>
              <div className="content">
                <Routes>
                  <Route path='/' element={<Home />} />
                  <Route path='/about/history' element={<History />} />
                  <Route path='/about/tokenomics' element={<Tokenomics />} />
                  <Route path='/about/socials' element={<Socials />} />
                  <Route path='/about/games' element={<Games />} />
                  <Route path='/memes' element={<Memes />} />
                  <Route path='/about/dao/mulsitig' element={<div />} />
                </Routes>
              </div>
            </div>
            <Footer />
            <div className="rainbowkit-connect-button">
              <ConnectButton showBalance={false} />
            </div>
          </React.Fragment>
        </RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

function AboutDropdown() {
  const [isHovered, setIsHovered] = useState(false);

  const toggleHover = () => {
    setIsHovered(!isHovered);
  };

  return (
    <div className="dropdown" onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
      <span className="nav-link">About</span>
      <div className={`dropdown-content ${isHovered ? 'show' : ''}`}>
        <NavLink key="tokenomics" to='/about/tokenomics' className="dropdown-link white-link">Tokenomics</NavLink>
        <NavLink key="history" to='/about/history' className="dropdown-link white-link">History</NavLink>
      </div>
    </div>
  );
}

function UtilitiesDropdown() {
  const [isHovered, setIsHovered] = useState(false);

  const toggleHover = () => {
    setIsHovered(!isHovered);
  };

  return (
    <div className="dropdown" onMouseEnter={toggleHover} onMouseLeave={toggleHover}>
      <span className="nav-link">Utilities</span>
      <div className={`dropdown-content ${isHovered ? 'show' : ''}`}>
        <NavLink to='/about/socials' className="nav-link">Socials</NavLink>
        <NavLink to='/about/games' className="nav-link">Games</NavLink>
      </div>
    </div>
  );
}

function DaoDropdownMenu() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="custom-button" onMouseEnter={toggleMenu} onMouseLeave={toggleMenu}>
      <a href='https://debank.com/profile/0x810b93f0dec3a84aa3b8a210d033858fbee41204' className="nav-link" target="_blank" rel="noopener noreferrer">
        Treasury
      </a>
    </div>
  );
}

export default App;
