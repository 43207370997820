import React from 'react';
import introImage from './img/intro.png'; 

interface IntroProps {
    
}

export const Intro: React.FC<IntroProps> = () => {
    return (
        <section>
            <img
                src={introImage}
                alt="Introduction"
                className="intro-image"
                style={{
                    width: '90%',
                    margin: '2% auto 1% auto', 
                    maxWidth: '100%',
                    display: 'block' 
                }}
            /> 
        </section>
    );
};
