import React, { useState } from 'react';
import introImage from './img/gamesutility.png';
import diceImage from './img/dice.png';
import coinImage from './img/coin.gif';
import rouletteImage from './img/roulette.gif';
import russianrouletteImage from './img/russianroulette.png';
import fireflyImage from './img/coin.gif';

export const Games = () => {
    const [isHeyHovered, setIsHeyHovered] = useState(false);
    const [isKairaHovered, setIsKairaHovered] = useState(false);
    const [isOrbHovered, setIsOrbHovered] = useState(false);
    const [isLensportHovered, setIsLensportHovered] = useState(false);
    const [isFireflyHovered, setIsFireflyHovered] = useState(false);

    const backgroundGradient = 'linear-gradient(to bottom right, #FCE38A, #F38181)';
    const blackTextColor = '#000000'; 

    const articleStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderRadius: '10px',
        boxShadow: '2px 2px 4px rgba(0,0,0,0.5)',
        transition: 'background 0.5s ease-in-out, color 0.5s ease-in-out',
        background: 'linear-gradient(to bottom right, #2c3930, #C3E5CD)',
        position: 'relative',
        minHeight: '260px',
        color: blackTextColor, 
        marginBottom: '20px', // Ajout d'espace entre chaque utilité
    };

    const imageStyle: React.CSSProperties = {
        width: '40%',
        marginRight: '20px',
        borderRadius: '10px',
    };

    const paragraphStyle: React.CSSProperties = {
        fontSize: '1em',
        width: '90%',
        textAlign: 'left',
    };

    const buttonnStyle: React.CSSProperties = {
        padding: '10px 20px',
        background: '#2c3930',
        color: '#FFFFFF',
        borderRadius: '5px',
        border: 'none',
        cursor: 'pointer',
        transition: 'background-color 0.3s, transform 0.3s, color 0.3s',
        fontFamily: 'Helvetica, sans-serif',
        fontWeight: 'bold',
        transform: 'translate(-50%, 1px)',
        position: 'absolute',
        bottom: '0',
        left: '50%',
    };

    const buttonBottomStyle: React.CSSProperties = {
        ...buttonnStyle,
        borderBottomLeftRadius: '0',
        borderBottomRightRadius: '0',
        boxShadow: 'none',
    };

    return (
        <div>
            <img
                src={introImage}
                alt="Introduction"
                className="intro-image"
                style={{
                    width: '100%',
                    marginTop: window.innerWidth < 769 ? '17%' : '4%',
                    maxWidth: '100%',
                    display: 'block',
                }}
            />

            <section style={{ textAlign: 'center', color: 'white' }}>
                <div style={{display: 'flex', gap: '20px', alignItems: 'flex-start'}}>
                    
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                    
                        <div style={{ background: backgroundGradient, marginBottom: '20px', borderRadius: '10px' }}>
                        
                            <h1 style={{color: blackTextColor}}>Single Player</h1>

                        </div>
                        <div
                            style={{
                                ...articleStyle,
                                background: isKairaHovered ? backgroundGradient : articleStyle.background,
                                color: isKairaHovered ? blackTextColor : 'inherit', 
                            }}
                            onMouseEnter={() => setIsKairaHovered(true)}
                            onMouseLeave={() => setIsKairaHovered(false)}
                        >
                            <h1>Roulette</h1>
                            <img src={rouletteImage} alt="Introduction" className="intro-image" style={{ ...imageStyle }} />
                            <p style={{ ...paragraphStyle }}> </p>

                            <div style={{ height: '40px' }}></div>
                            <a href="https://app.betswirl.com/roulette?c=137&token=pointless" target="_blank" rel="noopener noreferrer">
                                <button style={buttonBottomStyle}>Play Roulette</button>
                            </a>
                        </div>

                        <div
                            style={{
                                ...articleStyle,
                                background: isHeyHovered ? backgroundGradient : articleStyle.background,
                                color: isHeyHovered ? blackTextColor : 'inherit', 
                            }}
                            onMouseEnter={() => setIsHeyHovered(true)}
                            onMouseLeave={() => setIsHeyHovered(false)}
                        >
                            <h1>Dice</h1>
                            <img src={diceImage} alt="Introduction" className="intro-image" style={{ ...imageStyle }} />
                            <p style={{ ...paragraphStyle }}> </p>
  

                            <div style={{ height: '40px' }}></div>
                            <a href="https://app.betswirl.com/dice?c=137&token=pointless" target="_blank" rel="noopener noreferrer">
                                <button style={buttonBottomStyle}>Play Dice</button>
                            </a>
                        </div>

                        <div
                            style={{
                                ...articleStyle,
                                background: isOrbHovered ? backgroundGradient : articleStyle.background,
                                color: isOrbHovered ? blackTextColor : 'inherit', 
                            }}
                            onMouseEnter={() => setIsOrbHovered(true)}
                            onMouseLeave={() => setIsOrbHovered(false)}
                        >
                            <h1>Coin toss</h1>
                            <img src={coinImage} alt="Introduction" className="intro-image" style={{ ...imageStyle }} />
                            <p style={{ ...paragraphStyle }}> </p>

                            <div style={{ height: '40px' }}></div>
                            <a href="https://app.betswirl.com/coin-toss?c=137&token=pointless" target="_blank" rel="noopener noreferrer">
                                <button style={buttonBottomStyle}>Play Coin toss</button>
                            </a>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <div style={{ background: backgroundGradient, marginBottom: '20px', borderRadius: '10px' }}>
                            <h1 style={{color: blackTextColor}}>PvP</h1>
                        </div>
                        <div
                            style={{
                                ...articleStyle,
                                background: isLensportHovered ? backgroundGradient : articleStyle.background,
                                color: isLensportHovered ? blackTextColor : 'inherit', 
                            }}
                            onMouseEnter={() => setIsLensportHovered(true)}
                            onMouseLeave={() => setIsLensportHovered(false)}
                        >
                            <h1>Russian Roulette</h1>
                            <img src={russianrouletteImage} alt="Introduction" className="intro-image" style={{ ...imageStyle }} />
                            <p style={{ ...paragraphStyle }}> </p>

                            <div style={{ height: '40px' }}></div>
                            <a href="https://app.betswirl.com/russian-roulette?c=137&token=pointless&create" target="_blank" rel="noopener noreferrer">
                                <button style={buttonBottomStyle}>Play Russian Roulette</button>
                            </a>
                        </div>

                        <div
                            style={{
                                ...articleStyle,
                                background: isFireflyHovered ? backgroundGradient : articleStyle.background,
                                color: isFireflyHovered ? blackTextColor : 'inherit', 
                            }}
                            onMouseEnter={() => setIsFireflyHovered(true)}
                            onMouseLeave={() => setIsFireflyHovered(false)}
                        >
                            <h1>Coin flip</h1>
                            <img src={fireflyImage} alt="Introduction" className="intro-image" style={{ ...imageStyle }} />
                            <p style={{ ...paragraphStyle }}> </p>

                            <div style={{ height: '40px' }}></div>
                            <a href="https://app.betswirl.com/coin-flip-battle?c=137&token=pointless&create" target="_blank" rel="noopener noreferrer">
                                <button style={buttonBottomStyle}>Play Coin flip</button>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Games;
