import React, { useState, useEffect } from 'react';
import memes1 from './img/memes/memes1.jpg';
import memes2 from './img/memes/memes2.jpg';
import memes3 from './img/memes/memes3.jpg';
import memes4 from './img/memes/memes4.jpg';
import memes5 from './img/memes/memes5.jpg';
import memes6 from './img/memes/memes6.jpg';
import memes7 from './img/memes/memes7.jpg';
import memes8 from './img/memes/memes8.jpg';
import memes9 from './img/memes/memes9.jpg';
import memes10 from './img/memes/memes10.jpg';
import memes11 from './img/memes/memes11.jpg';
import memes12 from './img/memes/memes12.jpg';
import memes13 from './img/memes/memes13.jpg';
import memes14 from './img/memes/memes14.jpg';
import memes15 from './img/memes/memes15.jpg';
import memes16 from './img/memes/memes16.jpg';
import memes17 from './img/memes/memes17.jpg';
import memes18 from './img/memes/memes18.jpg';
import memes19 from './img/memes/memes19.jpg';
import memes20 from './img/memes/memes20.jpg';
import memes21 from './img/memes/memes21.jpg';
import memes22 from './img/memes/memes22.jpg';
import memes23 from './img/memes/memes23.jpg';
import memes27 from './img/memes/memes27.jpg';
import memes28 from './img/memes/memes28.jpg';
import memes30 from './img/memes/memes30.jpg';
import memes31 from './img/memes/memes31.jpg';
import memes32 from './img/memes/memes32.jpg';
import memes33 from './img/memes/memes33.jpg';
import memes34 from './img/memes/memes34.jpg';
import memes35 from './img/memes/memes35.jpg';
import memes36 from './img/memes/memes36.jpg';
import memes38 from './img/memes/memes38.jpg';
import memes39 from './img/memes/memes39.jpg';
import memes40 from './img/memes/memes40.jpg';
import memes41 from './img/memes/memes41.jpg';
import memes42 from './img/memes/memes42.jpg';
import memes44 from './img/memes/memes44.jpg';
import memes45 from './img/memes/memes45.jpg';
import memes46 from './img/memes/memes46.jpg';
import memes49 from './img/memes/memes49.jpg';
import memes50 from './img/memes/memes50.jpg';
import memes53 from './img/memes/memes53.jpg';
import memes54 from './img/memes/memes54.jpg';
import memes55 from './img/memes/memes55.jpg';
import memes56 from './img/memes/memes56.jpg';
import memes57 from './img/memes/memes57.jpg';
import memes58 from './img/memes/memes58.jpg';
import memes59 from './img/memes/memes59.jpg';
import memes60 from './img/memes/memes60.jpg';
import memes61 from './img/memes/memes61.jpg';
import memes64 from './img/memes/memes64.jpg';
import memes65 from './img/memes/memes65.jpg';
import memes66 from './img/memes/memes66.jpg';
import downloadIcon from '../img/download.png';
import titleImage from './img/title.png';
import title2Image from './img/title2.png';

export const MemesSection = () => {
    const memes = [
        memes1, memes2, memes3, memes4, memes5, memes6, memes7, memes8, memes9, memes10, 
        memes11, memes12, memes13, memes14, memes15, memes16, memes17, memes18, memes19, memes20, 
        memes21, memes22, memes23, memes23, memes27, memes28, 
        memes30, memes31, memes32, memes33, memes34, memes35, memes36, 
        memes38, memes39, memes40, memes41, memes42, memes44, memes45, memes46, 
        memes49, memes50, memes53, memes54, memes55, 
        memes56, memes57, memes58, memes59, memes60, memes61, memes64, 
        memes65, memes66
    ];
    

    const [currentmemesIndex, setCurrentmemesIndex] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [buttonHovered, setButtonHovered] = useState(false);

    const handleNextmemes = () => {
        setCurrentmemesIndex((prevIndex) => (prevIndex + 1) % memes.length);
    };

    const handlePreviousmemes = () => {
        setCurrentmemesIndex((prevIndex) => (prevIndex - 1 + memes.length) % memes.length);
    };

    const toggleModal = () => {
        setIsModalOpen((prevValue) => !prevValue);
    };

    const currentmemes = memes[currentmemesIndex];

    const handleMouseEnter = () => {
        setButtonHovered(true);
    };

    const handleMouseLeave = () => {
        setButtonHovered(false);
    };

    return (
        <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', fontFamily: 'Montserrat, sans-serif' }}>
            <img
                src={titleImage}
                alt="Introduction"
                className="intro-image"
                style={{
                    width: '80%',
                    marginBottom: '10px',
                    maxWidth: '100%',
                    marginTop: window.innerWidth < 768 ? '15%' : '0' 
                }}
            />
            <div style={{ position: 'relative', maxWidth: '600px' }}>
                <img
                    src={currentmemes}
                    alt="memes"
                    style={{ width: '500px', height: '350px', objectFit: 'cover', borderRadius: '10px', cursor: 'pointer' }}
                    onClick={toggleModal}
                />
                <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, border: '2px solid #C3E5CD', borderRadius: '10px', pointerEvents: 'none' }}></div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <button onClick={handlePreviousmemes} style={{ fontSize: '24px', backgroundColor: '#C3E5CD', color: '#2c3930', border: '2px solid #C3E5CD', borderRadius: '5px', padding: '10px', cursor: 'pointer', fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold' }}>&#9664;</button>
                <a href={currentmemes} download style={{ backgroundColor: '#C3E5CD', color: '#2c3930', border: '2px solid #C3E5CD', borderRadius: '5px', padding: '10px', cursor: 'pointer', textDecoration: 'none', fontFamily: 'Montserrat, sans-serif', display: 'inline-block', transition: 'background-color 0.3s', margin: '0 10px' }}>
                    <img src={downloadIcon} alt="Download" style={{ width: '20px', height: '20px', marginRight: '5px' }} />
                </a>
                <button onClick={handleNextmemes} style={{ fontSize: '24px', backgroundColor: '#C3E5CD', color: '#2c3930', border: '2px solid #C3E5CD', borderRadius: '5px', padding: '10px', cursor: 'pointer', fontFamily: 'Montserrat, sans-serif', fontWeight: 'bold' }}> &#9654;</button>
            </div>
            {isModalOpen && (
                <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', zIndex: 999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ backgroundColor: 'rgba(255, 255, 255, 0.8)', padding: '20px', borderRadius: '10px', boxShadow: '5px 5px 5px #C3E5CD', maxWidth: '80vw', maxHeight: '80vh', overflow: 'auto', position: 'relative' }}>
                        <img
                            src={currentmemes}
                            alt="memes"
                            style={{ width: '600px', height: '600px', objectFit: 'contain', borderRadius: '10px' }}
                        />
                        <button onClick={toggleModal} style={{ position: 'absolute', top: '10px', right: '10px', backgroundColor: '#C3E5CD', border: 'none', fontSize: '24px', cursor: 'pointer', color: '#2c3930', borderRadius: '50%', padding: '5px' }}>X</button>
                    </div>
                
                </div>
            )}
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <img
                    src={title2Image}
                    alt="Share your memes"
                    className="title2-image"
                    style={{
                        width: '80%',
                        marginBottom: '10px',
                        maxWidth: '80%',
                        marginTop: '10px' 
                    }}
                />
                <a href="https://discord.com/channels/1204019468594974790/1204146975747084369" style={{ fontSize: '1.2rem', marginTop: '10px', backgroundColor: buttonHovered ? '#fff' : '#C3E5CD', color: '#2c3930', border: '2px solid #C3E5CD', borderRadius: '5px', padding: '10px 20px', cursor: 'pointer', textDecoration: 'none', fontFamily: 'Montserrat, sans-serif', display: 'inline-block', transition: 'background-color 0.3s' }}
                   onMouseEnter={handleMouseEnter}
                   onMouseLeave={handleMouseLeave}
                >Share your Meme</a>
            </div>
        </section>
    );
};
