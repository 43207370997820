import React, { useState, useEffect } from 'react';
import tokenImage from './img/graph.png';
import titleImage from './img/title.png';
import breakdownImage from './img/breakdown.png';

export const Tokenomics = () => {
    const [tokenSupply, setTokenSupply] = useState(null);
    const [burnAmount, setBurnAmount] = useState(null);
    const [totalSupply, setTotalSupply] = useState(null);
    const [holders, setHolders] = useState(null);
    const [price, setPrice] = useState(null);




    
    const [isSupplyHovered, setIsSupplyHovered] = useState(false);
    const [isButton1Hovered, setIsButton1Hovered] = useState(false);
    const [isButton2Hovered, setIsButton2Hovered] = useState(false);
    const [isButton3Hovered, setIsButton3Hovered] = useState(false);

    function formatTokenSupply(supply) {
        const adjustedSupply = supply / (10 ** 18);
        const formattedSupply = adjustedSupply.toLocaleString('en-US');
        return formattedSupply;
    }

    function formatPriceAdjust(supply) {
        const adjustedSupply = supply / (10 ** 18);
        const formattedSupply = '$' + adjustedSupply.toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2});
        return formattedSupply;
    }

    function formatPrice(price) {
        const floatPrice = parseFloat(price)
        const formattedSupply = '$' + floatPrice.toLocaleString('en-US', {maximumFractionDigits: 2, minimumFractionDigits: 2});
        return formattedSupply;
    }

    useEffect(() => {
        function getSupply() {
            const api_key = 'TZMJ5CS4KMDMA3S2ANPSWM2KV257UBYEYV';
            const contract_address = '0x9B8cc6320F22325759B7D2CA5CD27347bB4eCD86';

            const url = `https://api.polygonscan.com/api?module=stats&action=tokensupply&contractaddress=${contract_address}&apikey=${api_key}`;

            fetch(url)
                .then(response => response.json())
                .then(data => {
                    if (data.status === '1') {
                        setTokenSupply(data.result);   
                    } else {
                        console.error('');
                    }
                })
                .catch(error => {
                    console.error('', error);
                });
        }

        function getBurnAmount() {
            const api_key = 'TZMJ5CS4KMDMA3S2ANPSWM2KV257UBYEYV';
            const contract_address = '0x9B8cc6320F22325759B7D2CA5CD27347bB4eCD86';

            const url = `https://api.polygonscan.com/api?module=account&action=tokenbalance&contractaddress=${contract_address}&address=0x000000000000000000000000000000000000dEaD&tag=latest&apikey=${api_key}`;

            fetch(url)
                .then(response => response.json())
                .then(data => {
                    if (data.status === '1') {
                        setBurnAmount(data.result);   
                    } else {
                        console.error('');
                    }
                })
                .catch(error => {
                    console.error('', error);
                });
        }

        function getHolders() {
            const api_key = '2dsjNimIMCdf5q0XqkYmIdRBe5o';
            const contract_address = '0x9B8cc6320F22325759B7D2CA5CD27347bB4eCD86';

            const url = `https://api.chainbase.online/v1/token/holders?chain_id=137&contract_address=${contract_address}&page=1&limit=1`;

            fetch(url, {
                headers: {
                    "accept": "application/json",
                    "x-api-key": api_key
                }})
                .then(response => response.json())
                .then(data => {
                    setHolders(data.count.toLocaleString('en-US'))
                })
                .catch(error => {
                    console.error('', error);
                });
        }

        function getPrice() {
            const api_key = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJub25jZSI6IjY0N2JjMWMxLTgxMDgtNDYxNy05MzhhLTc2YWI1ZjkyOTQwMCIsIm9yZ0lkIjoiMzgzNTI2IiwidXNlcklkIjoiMzk0MDg0IiwidHlwZUlkIjoiYTA4ZmRlZjYtY2Y4NS00Nzk2LWFmMDQtN2M4NzNlY2U2ZjMzIiwidHlwZSI6IlBST0pFQ1QiLCJpYXQiOjE3MTA4MDI2MjUsImV4cCI6NDg2NjU2MjYyNX0.iJlEXPRvNTtt6tNt1oeAM6yYu4fbUlTCjveGlziWfhk';
            const contract_address = '0x9B8cc6320F22325759B7D2CA5CD27347bB4eCD86';

            const url = `https://deep-index.moralis.io/api/v2.2/erc20/${contract_address}/price?chain=polygon&exchange=uniswapv3`;

            fetch(url, {
                headers: {
                    "accept": "application/json",
                    "X-API-Key": api_key
                }})
                .then(response => response.json())
                .then(data => {
                    setPrice(data.usdPrice);
                })
                .catch(error => {
                    console.error('', error);
                });
        }

        getSupply();
        getBurnAmount();
        getHolders();
        getPrice();
    }, []);

    const handleSupplyMouseEnter = () => {
        setIsSupplyHovered(true);
    };

    const handleSupplyMouseLeave = () => {
        setIsSupplyHovered(false);
    };

    const handleButton1MouseEnter = () => {
        setIsButton1Hovered(true);
    };

    const handleButton1MouseLeave = () => {
        setIsButton1Hovered(false);
    };

    const handleButton2MouseEnter = () => {
        setIsButton2Hovered(true);
    };

    const handleButton2MouseLeave = () => {
        setIsButton2Hovered(false);
    };

    const handleButton3MouseEnter = () => {
        setIsButton3Hovered(true);
    };

    const handleButton3MouseLeave = () => {
        setIsButton3Hovered(false);
    };

    const handlePolygonScanButtonClick = () => {
        window.open('https://polygonscan.com/token/0x9b8cc6320f22325759b7d2ca5cd27347bb4ecd86#balances', '_blank');
    };

    const handleDexScreenerButtonClick = () => {
        window.open('https://dexscreener.com/polygon/0xbca71dcdb10fc273be867243a0c8addfb49e0418', '_blank');
    };

    const cardsData = [
        {
            title: 'Airdrop 🪂',
            content: 'Airdrop to all Lens profile holders on December 15th 2023 (125,089 recipients)',
            details: 'https://hey.xyz/posts/0x01dd85-0x0119'
        },
        { 
            title: 'Treasury 💰', 
            content: 'Treasury to cover expenses for which the breakdown can be found below'
        },
        { 
            title: 'Team 👨‍💻', 
            content: 'Sydney_Bro’s team allocation', 
        },
        { 
            title: 'PartyBid 🎁', 
            content: 'PartyBid early contributors allocations (1B $pointless)'
        }
    ];

    const cardColors = [
        'linear-gradient(to bottom right, #2D422D, #162116)',
        'linear-gradient(to bottom right, #536C5B, #2E4A3A)',
        'linear-gradient(to bottom right, #354D3D, #1E3427)',
        'linear-gradient(to bottom right, #2D422D, #162116)'
    ];

    const handleCardMouseEnter = (index) => {
        const cardContainers = document.querySelectorAll('.card');
        cardContainers[index].style.background = 'linear-gradient(to bottom right, #FCE38A, #F38181)';
        cardContainers[index].style.color = '#000000';
        cardContainers[index].style.transition = 'background 0.3s, color 0.3s'; 
    };

    const handleCardMouseLeave = (index) => {
        const cardContainers = document.querySelectorAll('.card');
        cardContainers[index].style.background = cardColors[index];
        cardContainers[index].style.color = '#ffffff';
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', color: 'white', marginTop: '5%', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif' }}>
            
            <div style={{ marginBottom: ' 50px', textAlign: 'center', fontFamily: 'Helvetica, Arial, sans-serif', maxWidth: '90%', margin: 'auto' }}>
            <table style={{ width: 'calc(100% + 20px)', borderCollapse: 'collapse', marginTop: '20px', display: 'table', marginLeft: '-10px' }}>
    <thead>
        <tr>
            <th style={{ padding: '10px', background: 'linear-gradient(to bottom right, #2D422D, #162116)', color: 'white', borderRadius: '5px 5px 0 0' }}>$pointless Overview</th>
        </tr>
    </thead>
    <tbody>
        <tr>
            <td style={{ padding: '20px', background: 'linear-gradient(to bottom right, #536C5B, #2E4A3A)', borderRadius: '0 0 5px 5px', textAlign: 'center', boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.5)', borderBottom: 'none' }}>
                <div style={{ display: 'flex', flexDirection: window.innerWidth >= 768 ? 'row' : 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ marginBottom: window.innerWidth >= 768 ? '0' : '30px', marginRight: window.innerWidth >= 768 ? '20px' : '0', borderRight: window.innerWidth >= 768 ? '1px solid white' : 'none', paddingRight: window.innerWidth >= 768 ? '20px' : '0' }}>
                        <div>Initial Supply</div>
                        <br/>
                        <div>{formatTokenSupply(tokenSupply)}</div>
                    </div>
                    <div style={{ marginBottom: window.innerWidth >= 768 ? '0' : '30px', marginRight: window.innerWidth >= 768 ? '20px' : '0', borderRight: window.innerWidth >= 768 ? '1px solid white' : 'none', paddingRight: window.innerWidth >= 768 ? '20px' : '0' }}>
                        <div>Burn</div>
                        <br/>
                        <div>{formatTokenSupply(burnAmount)}</div>
                    </div>
                    <div style={{ marginBottom: window.innerWidth >= 768 ? '0' : '30px', marginRight: window.innerWidth >= 768 ? '20px' : '0', borderRight: window.innerWidth >= 768 ? '1px solid white' : 'none', paddingRight: window.innerWidth >= 768 ? '20px' : '0' }}>
                        <div>Total Supply</div>
                        <br/>
                        <div>{formatTokenSupply(tokenSupply - burnAmount)}</div>
                    </div>
                    <div style={{ marginBottom: window.innerWidth >= 768 ? '0' : '30px', marginRight: window.innerWidth >= 768 ? '20px' : '0', borderRight: window.innerWidth >= 768 ? '1px solid white' : 'none', paddingRight: window.innerWidth >= 768 ? '20px' : '0' }}>
                        <div>Holders</div>
                        <br/>
                        <div>{holders}</div>
                    </div>
                    <div style={{ marginBottom: window.innerWidth >= 768 ? '0' : '30px', marginRight: window.innerWidth >= 768 ? '20px' : '0', borderRight: window.innerWidth >= 768 ? '1px solid white' : 'none', paddingRight: window.innerWidth >= 768 ? '20px' : '0' }}>
                        <div>Price</div>
                        <br/>
                        <div>{'$' + price?.toFixed(10)}</div>
                    </div>
                    <div style={{ marginBottom: window.innerWidth >= 768 ? '0' : '30px', marginRight: window.innerWidth >= 768 ? '20px' : '0' }}>
                        <div>Market Cap</div>
                        <br/>
                        <div>{formatPriceAdjust(price * (tokenSupply - burnAmount))}</div>
                    </div>
                </div>
            </td>
        </tr>
    </tbody>
</table>
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px' }}>
                    <div style={{ marginRight: '20px' }}>
                        <button
                            className="custom-button"
                            onMouseEnter={handleButton1MouseEnter}
                            onMouseLeave={handleButton1MouseLeave}
                            onClick={handlePolygonScanButtonClick}
                            style={{
                                background: isButton1Hovered ? 'linear-gradient(to bottom right, #982FCF, #7B3EE1)' : 'linear-gradient(to bottom right, #2D422D, #162116)',
                                color: 'white',
                                padding: '10px 20px',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                transition: 'background 0.3s',
                                fontWeight: 'bold',
                                boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.5)'
                            }}
                        >
                            Polygonscan
                        </button>
                    </div>
                    <div style={{ marginRight: '20px' }}>
                        <button
                            className="custom-button"
                            onMouseEnter={handleButton2MouseEnter}
                            onMouseLeave={handleButton2MouseLeave}
                            onClick={handleDexScreenerButtonClick}
                            style={{
                                background: isButton2Hovered ? 'linear-gradient(to bottom right, #353535, #000)' : 'linear-gradient(to bottom right, #2D422D, #162116)',
                                color: 'white',
                                padding: '10px 20px',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                transition: 'background 0.3s',
                                fontWeight: 'bold',
                                boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.5)'
                            }}
                        >
                            DexScreener
                        </button>
                    </div>
                    <div>
                        <button
                            className="custom-button"
                            onMouseEnter={handleButton3MouseEnter}
                            onMouseLeave={handleButton3MouseLeave}
                            style={{
                                background: isButton3Hovered ? 'linear-gradient(to bottom right, #FE007A, #990553)' : 'linear-gradient(to bottom right, #2D422D, #162116)',
                                color: 'white',
                                padding: '10px 20px',
                                border: 'none',
                                borderRadius: '5px',
                                cursor: 'pointer',
                                transition: 'background 0.3s',
                                fontWeight: 'bold',
                                boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.5)'
                            }}
                        >
                            Uniswap
                        </button>
                    </div>
                </div>
                <img src={titleImage} alt="Token Image" className="title-image" />
            </div>

            <div style={{ marginBottom: '40px', display: 'grid', gridTemplateColumns: '1fr', gap: '10px', maxWidth: '90%', margin: 'auto' }}>
                {tokenSupply && (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '10px', fontFamily: 'Bowlby One SC, sans-serif', marginTop: window.innerWidth < 768 ? '5%' : '0' }}>
                        <img src={tokenImage} alt="Token Image" className="token-image" />
                    </div>
                )}
            </div>
            <div className="cards-container" style={{ display: 'flex', justifyContent: window.innerWidth >= 768 ? 'space-around' : 'center', width: '100%', flexWrap: 'wrap' }}>
                {cardsData.map((card, index) => (
                    <div key={index} className="card" onMouseEnter={() => handleCardMouseEnter(index)} onMouseLeave={() => handleCardMouseLeave(index)} style={{ background: cardColors[index], padding: '20px', borderRadius: '10px', width: '200px', margin: '10px', textAlign: 'center', boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.5)', transition: 'background 0.3s, color 0.3s', fontFamily: 'Helvetica, Arial, sans-serif', position: 'relative' }}>
                        <h3>{card.title}</h3>
                        <p>{card.content}</p>
                        {card.details && (
                            <div style={{ marginTop: 'auto' }}>
                                
                                
                                <button
                                    className="custom-button2"
                                    style={{
                                        background: 'linear-gradient(to bottom right, #2D422D, #162116)',
                                        color: 'white',
                                        padding: '5px 10px',
                                        border: 'none',
                                        borderRadius: '5px',
                                        cursor: 'pointer',
                                        transition: 'background 0.3s',
                                        fontWeight: 'bold',
                                        position: 'absolute',
                                        bottom: '5px',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                        boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.5)'
                                    }}
                                >
                                    <a href={card.details} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>Details</a>
                                </button>
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <img src={breakdownImage} alt="Breakdown Image" className="breakdown-image" />
            <ul className="custom-list">
                <li>10% Community Rewards</li>
                <li>4% Future Uses</li>
                <li>3% Liquidity</li>
                <li>2,2% Community Bounties (incl. 0,6% spent for early ones)</li>
                <h5>*The above breakdown doesn’t include any potential extra inflows.</h5>
            </ul>
            <ul className="custom-list">
                <h3>$pointless earned on the Lens profile address:</h3>
                <li>50% to Treasury💰</li>
                <li>50% Burned Monthly🔥</li>
            </ul>
        </div>
    );
};

export default Tokenomics;
