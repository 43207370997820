import React, { useEffect, useRef, useState } from 'react';
import { Intro } from '../componentsHome/Intro';
import './Home.css';
import memesImage from './img/memes.jpg';


export const Home = () => {
    const canvasRef = useRef(null);
    const [score, setScore] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [secondModalOpen, setSecondModalOpen] = useState(false);
    const [likeClicked, setLikeClicked] = useState(false);
    const [shareClicked, setShareClicked] = useState(false);
    const [discordClicked, setDiscordClicked] = useState(false);
    const [lensClicked, setLensClicked] = useState(false);
    const [claimingPoints, setClaimingPoints] = useState(false);
    const [claimButtonDisabled, setClaimButtonDisabled] = useState(true);
    const [goToGifDisabled, setGoToGifDisabled] = useState(true);
    const [message, setMessage] = useState("");
    const [isButton1Hovered, setIsButton1Hovered] = useState(false);
    const [isButton2Hovered, setIsButton2Hovered] = useState(false);
    const [isButton3Hovered, setIsButton3Hovered] = useState(false);
    const colors = ['#FF5733', '#33FF57', '#5733FF', '#33BFFF', '#FF33BF', '#BFFF33', '#FF3333', '#3333FF', '#33FFBF'];
    const minValue = 20000;
    const maxValue = 120000;
    const numTokens = 23;
    const tokenRadius = window.innerWidth < 600 ? 30 : 70;
    const tokenFontSize = window.innerWidth < 600 ? '10px' : '12px';

    const formatNumber = (number) => {
        if (number < 1000) {
            return number.toString();
        } else if (number < 1000000) {
            return (number / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
        } else if (number < 1000000000) {
            return (number / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
        } else {
            return (number / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
        }
    };

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        let tokens = [];

        const updateCanvasSize = () => {
            const parent = canvas.parentElement;
            const width = parent.offsetWidth * 0.8;
            const maxHeight = window.innerHeight * 0.5;
            const totalTokenHeight = numTokens * tokenRadius * 2;
            const height = Math.min(maxHeight, totalTokenHeight);
            canvas.width = width;
            canvas.height = height;
            for (let i = 0; i < numTokens; i++) {
                tokens.push(createToken());
            }
            tokens.push(createSpecialToken());
        };

        const createToken = () => ({
            x: Math.random() * canvas.width,
            y: Math.random() * canvas.height,
            radius: tokenRadius,
            speedX: Math.random() * 2 - 1,
            speedY: Math.random() * 2 - 1,
            color: colors[Math.floor(Math.random() * colors.length)],
            value: Math.floor(Math.random() * (maxValue - minValue + 1)) + minValue
        });

        const createSpecialToken = () => ({
            x: Math.random() * canvas.width,
            y: Math.random() * canvas.height,
            radius: tokenRadius,
            speedX: Math.random() * 2 - 1,
            speedY: Math.random() * 2 - 1,
            color: 'black',
            value: 1000000,
            special: true
        });

        updateCanvasSize();

        const updateTokensPosition = () => {
            context.clearRect(0, 0, canvas.width, canvas.height);
            tokens.forEach(token => {
                context.beginPath();
                context.arc(token.x, token.y, token.radius, 0, Math.PI * 2);
                context.fillStyle = token.color;
                context.fill();
                context.fillStyle = '#C3E5CD';
                context.font = `bold ${tokenFontSize} Arial`;
                context.textAlign = 'center';

                if (token.color === 'black') {
                    context.fillStyle = '#FFFFFF';
                } else {
                    context.fillStyle = '#000000';
                }

                if (token.special) {
                    context.fillText(`+1M pts`, token.x, token.y + 5);
                } else {
                    context.fillText(`+${formatNumber(token.value)} pts`, token.x, token.y + 5);
                }
                token.x += token.speedX;
                token.y += token.speedY;
                if (token.x - token.radius < 0 || token.x + token.radius > canvas.width) {
                    token.speedX *= -1;
                }
                if (token.y - token.radius < 0 || token.y + token.radius > canvas.height) {
                    token.speedY *= -1;
                }
            });
            requestAnimationFrame(updateTokensPosition);
        };

        const handleCanvasClick = (event) => {
            const rect = canvas.getBoundingClientRect();
            const x = event.clientX - rect.left;
            const y = event.clientY - rect.top;
            tokens.forEach((token, index) => {
                if (!token.special) {
                    const distance = Math.sqrt((x - token.x) ** 2 + (y - token.y) ** 2);
                    if (distance <= token.radius) {
                        setScore(prevScore => prevScore + token.value);
                        tokens.splice(index, 1);
                    }
                }
            });
        };

        canvas.addEventListener('mousedown', handleCanvasClick);
        updateTokensPosition();

        return () => {
            canvas.removeEventListener('mousedown', handleCanvasClick);
        };
    }, []);

    useEffect(() => {
        if (likeClicked && shareClicked && discordClicked && lensClicked) {
            setClaimButtonDisabled(false);
            setGoToGifDisabled(false);
        }
    }, [likeClicked, shareClicked, discordClicked, lensClicked]);

    const handleGetPoints = () => {
        if (!modalOpen) {
            setModalOpen(true);
            calculateMessage(score);
        }
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    const openSecondModal = () => {
        setSecondModalOpen(true);
    };

    const closeSecondModal = () => {
        setSecondModalOpen(false);
    };

    const handleCloseTrollModal = () => {
        setSecondModalOpen(false);
        setModalOpen(false);
    };

    const decrementPoints = () => {
        setScore(prevScore => {
            if (prevScore <= 0) {
                return 0;
            }
            return prevScore - 1;
        });
    };

    const calculateMessage = (points) => {
        let message = "";
        if (points < 350000) {
            message = "Well done ! " + formatNumber(points) + " points accumulated but you need a bit more to claim it, grind harder anon !";
        } else if (points >= 350000 && points < 1000000) {
            message = "Impressive ! " + formatNumber(points) + " points accumulated, almost there !";
        }
        setMessage(message);
    };

    const handleLike = () => {
        window.open('https://twitter.com/pointless_wtf', '_blank');
        setLikeClicked(true);
    };

    const handleShare = () => {
        const textToShare = `I just scored ${formatNumber(score)} pointless Points on the @pointless_wtf game! Check it out here: https://pointless.wtf`;
        window.open(`https://twitter.com/intent/tweet?text=${encodeURIComponent(textToShare)}`, '_blank');
        setShareClicked(true);
    };

    const handleJoinDiscord = () => {
        window.open('https://discord.gg/yPhqdRaCew', '_blank');
        setDiscordClicked(true);
    };

    const handleFollowLens = () => {
        window.open('https://lensvert.xyz/u/pointless', '_blank');
        setLensClicked(true);
    };

    const handleClaim = () => {
        setClaimingPoints(true);
        const maxSpeed = 1000000;
        const minSpeed = 1000;
        const interval = setInterval(() => {
            setScore(prevScore => {
                if (prevScore <= 0) {
                    clearInterval(interval);
                    setClaimingPoints(false);
                    setClaimButtonDisabled(true);
                    setSecondModalOpen(false);
                    return 0;
                }
                const speed = Math.max(minSpeed, maxSpeed * (prevScore / 15000000));
                return Math.floor(prevScore - speed);
            });
        }, 50);
        setSecondModalOpen(true);
        closeSecondModal();
    };

    const handleOpenSecondModal = () => {
        closeModal();
        openSecondModal();
    };

    const handlePolygonScanButtonClick = () => {
        console.log("PolygonScan button clicked");
    };

    const handleDexScreenerButtonClick = () => {
        console.log("DexScreener button clicked");
    };

    const handleUniswapButtonClick = () => {
        console.log("Uniswap button clicked");
    };

    const handleButton1MouseEnter = () => {
        setIsButton1Hovered(true);
    };

    const handleButton1MouseLeave = () => {
        setIsButton1Hovered(false);
    };

    const handleButton2MouseEnter = () => {
        setIsButton2Hovered(true);
    };

    const handleButton2MouseLeave = () => {
        setIsButton2Hovered(false);
    };

    const handleButton3MouseEnter = () => {
        setIsButton3Hovered(true);
    };

    const handleButton3MouseLeave = () => {
        setIsButton3Hovered(false);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '100vh' }}>
            <div style={{ marginTop: '0px' }}>
                <Intro />
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px', marginBottom: '40px' }}>
                <div style={{ marginRight: '20px' }}>
                    <a
                        className="custom-button"
                        href="https://polygonscan.com/token/0x9b8cc6320f22325759b7d2ca5cd27347bb4ecd86#balances"
                        target="_blank"
                        rel="noopener noreferrer"
                        onMouseEnter={handleButton1MouseEnter}
                        onMouseLeave={handleButton1MouseLeave}
                        style={{
                            background: isButton1Hovered ? 'linear-gradient(to bottom right, #982FCF, #7B3EE1)' : 'linear-gradient(to bottom right, #2D422D, #162116)',
                            color: 'white',
                            padding: '10px 20px',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            transition: 'background 0.3s',
                            fontWeight: 'bold',
                            boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.5)',
                            textDecoration: 'none',
                            fontFamily: 'Helvetica, sans-serif',
                            fontSize: '0.8em',
                            marginBottom: '10px'
                        }}
                    >
                        PolygonScan
                    </a>
                </div>
                <div style={{ marginRight: '20px' }}>
                    <a
                        className="custom-button"
                        href="https://dexscreener.com/polygon/0xbca71dcdb10fc273be867243a0c8addfb49e0418"
                        target="_blank"
                        rel="noopener noreferrer"
                        onMouseEnter={handleButton2MouseEnter}
                        onMouseLeave={handleButton2MouseLeave}
                        style={{
                            background: isButton2Hovered ? 'linear-gradient(to bottom right, #353535, #000)' : 'linear-gradient(to bottom right, #2D422D, #162116)',
                            color: 'white',
                            padding: '10px 20px',
                            border: 'none',
                            borderRadius: '5px',
                            cursor: 'pointer',
                            transition: 'background 0.3s',
                            fontWeight: 'bold',
                            boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.5)',
                            textDecoration: 'none',
                            fontFamily: 'Helvetica, sans-serif',
                            fontSize: '0.8em',
                            marginBottom: '10px'
                        }}
                    >
                        DexScreener
                    </a>
                </div>
                <div>
                <a
    className="custom-button"
    href="https://swap.defillama.com/?chain=polygon&from=0x0000000000000000000000000000000000000000&to=0x9b8cc6320f22325759b7d2ca5cd27347bb4ecd86"
    onClick={handleUniswapButtonClick}
    onMouseEnter={handleButton3MouseEnter}
    onMouseLeave={handleButton3MouseLeave}
    target="_blank"
    style={{
        background: isButton3Hovered ? 'linear-gradient(to bottom right, #5FAD56, #A5CC82)' : 'linear-gradient(to bottom right, #2D422D, #162116)',
        color: 'white',
        padding: '10px 20px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        transition: 'background 0.3s',
        fontWeight: 'bold',
        boxShadow: '0px 6px 8px rgba(0, 0, 0, 0.5)',
        textDecoration: 'none',
        fontFamily: 'Helvetica, sans-serif',
        fontSize: '0.8em',
        marginBottom: '10px'
    }}
>
    Buy $pointless
</a>

                </div>
            </div>
                <h3 style={{ color: 'white', textAlign: 'center' }}>Play the e-beggar game,<br />Just click the bubbles to earn points 👇</h3>
            </div>
            <div style={{ width: '80%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            </div>
            <canvas
                ref={canvasRef}
                style={{ border: '5px solid #C3E5CD', borderRadius: '10px', boxShadow: '5px 5px 5px #3B4C40' }}
            />
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: '15px' }}>
                <p style={{ fontWeight: 'bold', fontSize: '1.5em', fontFamily: "'Bowlby One SC Regular', Helvetica, sans-serif", color: '#FFFFFF', marginRight: '10px' }}>{formatNumber(score)} Points</p>
                <button onClick={handleGetPoints} style={{ padding: '6px 12px', fontSize: '0.8em', fontWeight: 'bold', borderRadius: '5px', backgroundColor: '#C3E5CD', color: '#2c3930', border: 'none', cursor: 'pointer', boxShadow: '2px 2px 5px #888888', fontFamily: 'Bowlby One SC Regular', display: score === 0 ? 'none' : 'block' }}>Check eligibility</button>
            </div>
            
            {modalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={closeModal}>&times;</span>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <p>{message}</p>
                            {score >= 1000000 && (
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                    <p>Congrats, you've reached {formatNumber(score)} points ! Join the community to check eligibility:</p>
                                    <button onClick={handleLike} className={`modal-button like-button ${likeClicked ? 'liked' : ''}`}>Follow on X</button>
                                    <button onClick={handleShare} className={`modal-button share-button ${shareClicked ? 'shared' : ''}`}>Share on X</button>
                                    <button onClick={handleFollowLens} className={`modal-button lens-button ${lensClicked ? 'followed' : ''}`}>Follow on Lens</button>
                                    <button onClick={handleJoinDiscord} className={`modal-button discord-button ${discordClicked ? 'joined' : ''}`}>Join Discord</button>
                                    <button onClick={handleOpenSecondModal} className={`modal-button go-to-gif-button`} disabled={goToGifDisabled}>Check eligibility</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}

            {secondModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <span className="close" onClick={handleCloseTrollModal}>&times;</span>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img src={memesImage} alt="Memes" style={{ maxWidth: '100%', height: 'auto', marginBottom: '10px' }} />
                            <button onClick={handleClaim} className={`modal-button claim-button ${likeClicked && shareClicked && discordClicked && lensClicked ? '' : 'disabled'}`} disabled={claimButtonDisabled}>Verify Anyway</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
