import React from 'react';
import logoSquare from './img/logoSquare.png';
import discordLogo from './img/discord.svg';
import twitterLogo from './img/twitter.svg';
import lensLogo from './img/lens.svg';
import hervas from './img/hervas.png';

const Footer = () => {
    return (
        <div style={{ overflowX: 'hidden' }}>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <img src={hervas} alt="hervas" className="hervas-image" />
            </div>

            <footer className="footer" style={{ backgroundColor: 'transparent', width: '100%' }}>
            <div className="footer-column" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
    <ul>
        <li><a href="https://www.pointless.wtf/">Home</a></li>
        <li><a href="https://www.pointless.wtf/about/Tokenomics">Tokenomics</a></li>
        <li><a href="https://www.pointless.wtf/about/History">History</a></li>
    </ul>
</div>

                <div className="footer-column" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <ul>
                        <li><a href="https://www.pointless.wtf/Memes">Memes</a></li>
                        <li><a href="https://www.pointless.wtf/about/Utilities" >Utilities</a></li>
                        <li><a href="https://hey.xyz/posts/0x01dd85-0x0119" target="_blank">Genesis Post </a></li>
                    </ul>
                </div>
                <div className="footer-column">
                    <ul>
                    <li><a href="https://polygonscan.com/token/0x9b8cc6320f22325759b7d2ca5cd27347bb4ecd86#balances" target="_blank">Polygonscan</a></li>
                        <li><a href="https://drive.google.com/drive/folders/1oMUTHjX59_QxL-RWQLqrEIB4rIop2eRA" target="_blank">Branding Kit</a></li>
                        <li><a href="https://debank.com/profile/0x810b93f0dec3a84aa3b8a210d033858fbee41204" target="_blank">Treasury</a></li>
                    
                    </ul>
                </div>
                
            </footer>

            <div style={{ bottom: '0', left: '0', color: 'white', fontFamily: 'Montserrat, sans-serif', fontSize: '14px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', padding: '10px', width: '100%' }}>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={logoSquare} alt="LogoSquare" style={{ height: '40px', marginRight: '5px' }} />

                    </div>
                    
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    
                    <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                        <a href="https://twitter.com/pointless_wtf" target="_blank" style={{ margin: '0 0px' }}><img src={twitterLogo} alt="Twitter Logo" style={{ height: '25px', marginRight: '15px' }} /></a>
                        <a href="https://discord.gg/yPhqdRaCew" target="_blank" style={{ margin: '0 0px' }}><img src={discordLogo} alt="Discord Logo" style={{ height: '25px', marginRight: '15px' }} /></a>
                        <a href="https://lensvert.xyz/u/pointless" target="_blank" style={{ marginRight: '30px' }}><img src={lensLogo} alt="Hey Logo" style={{ height: '25px', marginRight: '15px' }} /></a>
                    </div>
                </div>
                

            </div>
            <p style={{ margin: '0', textAlign: 'center', color: '#FFFFFF' }}>contact@pointless.wtf</p>
        </div>
    );
};

export default Footer;
